.ant-tabs-top {
  .ant-tabs-nav-scroll {
    background-color: $color-main-background;

    .ant-tabs-tab {
      background-color: $color-tab-not-active;
      width: 26.21rem;
      max-width: 26.21rem;
      border-bottom-right-radius: unset;
      border-bottom-right-radius: unset;
      font-size: 1.0625rem;
      font-weight: normal;
      padding: 1rem 2rem;
      border-bottom-right-radius: unset;
      border-bottom-left-radius: unset;

      &::before {
        display: unset;
      }

      &.ant-tabs-tab-active {
        background-color: $color-background;
        cursor: unset;
      }

      &:hover {
        color: $color-txt-black;
      }
    }
  }
}

.ant-tabs-ink-bar {
  height: 0;
}

.ant-tabs-bar {
  border-bottom: 0 none;
  color: $color-txt-black;
  margin-bottom: unset;
}

.ant-tabs-nav .ant-tabs-tab-active {
  color: $color-txt-black;
}

.ant-tabs-ink-bar {
  height: 0;
}

.ant-tabs-tab-active {
  background-color: transparent;
}

.ant-tabs-tabpane {
  color: $color-txt-black;
  font-size: 0.9375rem;

  a {
    font-size: 0.9375rem;
    color: $color-sme-input-icons;
  }

  .block {
    padding: unset;
  }
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: $color-txt-black;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title,
.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
  color: $color-lightgray;
}

.ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  border-bottom-color: $color-lightgray;
}

.ant-steps {
  padding: 1rem 2rem;

  .ant-steps-item-title {
    font-size: 0.9375rem;
    font-weight: normal;
  }

  .ant-steps-item-description {
    color: $color-txt-black;
  }

  .ant-steps-item-finish {
    .ant-steps-item-title,
    .ant-steps-item-description {
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .ant-steps-item-icon {
    display: none;
  }

  .ant-steps-item-title {
    position: initial;
  }

  .ant-steps-item-content {
    width: 100%;
    .ant-steps-item-title {
      border-bottom: 0.0625rem solid $color-txt-black;
      width: 100%;
    }

    .ant-steps-item-description {
      padding-top: 0.375rem;
    }
  }
  .ant-steps-item,
  .ant-steps-item:last-child {
    flex: 0.5 1;
  }
}
