.payment-modal {
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 0.5rem;
  }

  .ContractFilter {
    box-shadow: $box-shadow-personal-assistance;
  }

  .currency-number,
  .currency-number::placeholder {
    font-size: 1.875rem;
    text-indent: 40%;
    color: $color-txt-black;
    background-color: $bg-contract-selected;
    border-bottom: unset;
    border-radius: 0.5rem;
    margin-top: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 100%;
  }

  p.payment-modal-legal-text p {
    font-size: 0.8125rem;
    margin-top: 1.5rem;
    color: $color-payment-legal-info;
  }

  &-info {
    color: $color-txt-black;
  }
}
