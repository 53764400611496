.grid-filter-block {
  label {
    font-size: $tiny-text;
  }

  button.filter-search {
    padding: 0.625rem 1.25rem;
    border: 0.0625rem $color-sme-input-icons solid;
    color: $color-sme-input-icons;
    margin-top: 0.7rem;
    height: auto;
    line-height: inherit;
    float: right;
    
    &:hover {
      background-color: $color-report-checkbox-checked-bg;
      border-color: $color-report-checkbox-checked-bg;
    }
  }

  .datepicker {
    padding: unset;
  }
}
