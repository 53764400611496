.overdue-amount {
  &.block {
    padding-top: 1.063rem;
    padding-bottom: 1.063rem;
  }

  &_title,
  &_value {
    line-height: 2.4375rem;
    color: initial;
  }

  &_title {
    font-size: $small-text;
    font-weight: 500;
  }

  &_value {
    font-size: $medium-text;
  }

  @include colored-right-border($color-contract-overdue-txt);
}
