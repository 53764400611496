.faq-page {
  margin-top: 1.175rem;

  &__loading-container {
    min-height: 20rem;
  }

  &__topic-collapse {
    background-color: $color-background;
  }

  &__category-heading {
    margin: 0;
  }

  &__category {
    box-shadow: 0 0.8125rem 1.125rem 0 rgba(0, 0, 0, 0.05);
    .ant-collapse-content-box {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 1.875rem;
    }
  }

  &__topic {
    border-radius: 0;
    .ant-collapse-content-box {
      padding-bottom: 1rem;
    }

    .ant-collapse-header {
      border-radius: 0;
    }
  }

  &__topic-heading {
    margin: 0;
    font-size: 0.9375rem;
    font-weight: 600;
  }

  &__topic-body {
    font-size: 0.9375rem;
    color: $color-txt-black;
    padding-right: 3.125rem;

    p {
      color: inherit;
      font-size: inherit;
    }

    td {
      min-width: 12.5rem;
    }
  }

  &__arrow-icon {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &--active {
      transform: rotate(180deg);
    }
  }

  &__no-data {
    text-align: center;

    &__button {
      margin: 2rem auto 0;
      display: block;
    }
  }

  .ant-collapse

  .ant-collapse-item{
    &:hover{
      background-color: #eaf0fE;
    }
  }

    .ant-collapse-item
    .ant-collapse-content
    .ant-collapse-item.faq-page__topic {
    margin: 0 4rem;
    border-radius: 0;

    border-bottom: 1px solid $color-tables-border-not-active;

    &:first-child {
      border-top: 1px solid $color-tables-border-not-active;
    }

    .ant-collapse-header {
      padding: 1rem 0;
    }
  }

  .ant-collapse .ant-collapse-item.faq-page__category .ant-collapse-header {
    padding-left: 4rem;
  }
}
