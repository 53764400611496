.status {
    font-size: 1.313rem;

  &.activeStatus {
    color: #66CC33;
  }

  &.expiredStatus {
    color: #a5b2ff;
  }

  &.suspendedStatus {
    color: #ff6666;
  }

  &.defaultStatus {
    color: #ffcc99;
  }
}
