$red-strong: #FF2D2D;
$red-weak: #FFF9F9;

.deprecation-message_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background-color: $red-weak;
    border: 1px solid $red-strong;
    padding: 16px 32px;
    border-radius: 8px;
    margin-bottom: 40px;
    max-width: 528px;
    gap: 16px;


    &.global-banner {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid $red-strong;
        margin-bottom: 0;
        position: sticky;
        top: 0;
        z-index: 999999;
    }

    .icon-container {
        width: 24px;
        min-width: 24px;
        height: 24px;
        border: 1px solid $red-strong;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $red-strong;
    }

    .message-content-container {
        display: flex;
        flex-direction: column;
        gap: 16px;

        &.global-banner-message {
            max-width: 1202px;
            flex-direction: row;
            align-items: center;
            gap: 32px;
        }

        .message_container {
            display: flex;
            flex-direction: column;
            gap: 8px;

            .warning-header {
                color: $red-strong;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                word-wrap: break-word
            }

            .warning-message {
                color: $red-strong;
                font-size: 14px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0.14px;
                word-wrap: break-word;

                a {
                    color: $red-strong;
                    text-decoration: underline;
                }
            }
        }

        .ant-btn {
            &.danger {
                height: 32px;
                padding-left: 18px;
                padding-right: 18px;
                padding-top: 4px;
                padding-bottom: 4px;
                border-radius: 10px;
                justify-content: center;
                align-items: center;
                gap: 10px;
                display: inline-flex;
                background-color: $red-strong;
                border: 1px solid $red-strong;

                &:hover {
                    background-color: $red-weak;
                    border: 1px solid $red-strong;
                    color: $red-strong;
                }
            }
        }
    }
}