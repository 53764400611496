.left-menu-wrapper {
  background: inherit;
  margin-left: 3.9375rem;
  padding-top: 3rem;
  width: 18.75rem;

  .sideNav {
    a {
      font-size: 0.9375rem;
      display: block;
      margin-bottom: 2rem;
      display: flex;

      span {
        margin-left: 20px;
        opacity: 0.8;
      }
      img {
        opacity: 0.5;
      }

      &.nav-active,
      &:hover {
        color: $color-sme-input-icons;

        img {
          color: $color-sme-input-icons;
          opacity: 1;
        }
        span {
          opacity: 1;
        }
      }
    }

    .nav-active {
      pointer-events: none;
    }
  }

  .personal-assistance {
    position: relative;
    box-sizing: border-box;
    font-size: 0.825rem;
    display: flex;
    flex-direction: column;
    background-color: $bg-personal-assistance;
    color: black;
    box-shadow: $box-shadow-personal-assistance;
    border-radius: 1rem;
    margin-top: 18.75rem;
    margin-left: -2rem;
    max-height: 10rem;
    height: 100%;
    width: 13rem;
    transition: all 0.4s ease-out;

    .assistant-icon-wrapper {
      position: absolute;
      top: 0.725rem;
      right: 0.725rem;
    }

    &__can-we-help-you {
      height: fit-content;
      position: absolute;
      bottom: 0.7rem;
      left: 0.7rem;
      &__question {
        font-size: 0.725rem;
        font-weight: 500;
        transition: all ease-out;
        color: $color-personal-assistance-question;
        word-break: break-word;
      }
    }

    &__expandable,
    &__expandable-top {
      transition: inherit;
      width: 13rem;
      max-height: 0;
      opacity: 0;
      span {
        padding-left: 0.3rem;
      }
    }

    &__expandable {
      box-shadow: $box-shadow-personal-assistance;
      border-radius: 1rem;
      padding: 0 0.7rem 0 0.7rem;
      &__question {
        padding: 0.5rem 0 0.2rem 0;
        font-size: 0.725rem;
        font-weight: 500;
        transition: all ease-out;
        color: $color-personal-assistance-question;
        margin: 0;
        word-break: break-word;
      }
    }
    &__expandable-top {
      padding: 0 0.7rem 0.3rem 0.7rem;
      &__name {
        padding-top: 1.8rem;
        font-weight: 500;
        margin: 0;
        font-weight: bold;
      }
    }
    .assistant-photo {
      border-radius: 50%;
      width: 3.75rem;
      height: 3.75rem;
      margin: 0.7rem 0 0 0.7rem;
    }

    .assistance-expandable-item {
      display: flex;
      margin: 0.3rem 0 0.3rem -0.25rem;
      padding: 0.2rem 0.25rem;
      border-radius: 0.8rem;
      overflow: hidden;
      font-size: 0.8rem;
      width: 12.2rem;
      background-color: $bg-personal-assistance-item;

      &--FAQ {
        cursor: pointer;
        background-color: $bg-personal-assistance-faq;
        opacity: 0.8;
        transition: opacity 0.3s ease-out;
        &:hover {
          opacity: 1;
        }
      }
      &__text {
        word-wrap: none;
        width: 10rem;
        overflow: hidden;
        margin: 0 0.3rem;

        span {
          display: inline-block;
          width: fit-content;
          transition: all 0.3s ease-out;
        }
      }
    }
    &:hover {
      margin-top: 14.7rem;
      max-height: 14.1rem;

      .assistant-question {
        margin-top: 0.275rem;
        margin-bottom: 0;
      }

      .personal-assistance__expandable {
        transition-delay: 0.15s;
        max-height: 10rem;
        opacity: 1;
      }
      .personal-assistance__expandable-top {
        max-height: 10rem;
        margin-top: 0;
        opacity: 1;
      }
      .personal-assistance__can-we-help-you {
        top: 0.7rem;
      }
    }
  }
  .personal-assistance.with-photo {
    &:hover {
      margin-top: 10.2rem;
      max-height: 18.6rem;

      .assistant-question {
        margin-top: 0.275rem;
        margin-bottom: 0;
      }
      .personal-assistance__expandable {
        max-height: 10rem;
        opacity: 1;
      }
      .personal-assistance__expandable-top {
        max-height: 10rem;
        margin-top: 0;
        opacity: 1;
        padding: 0 0.7rem 0.3rem 0.7rem;
      }
      .personal-assistance__can-we-help-you {
        top: 5rem;
      }
    }
  }
}
