.terms-and-conditions {
  &__disclaimer {
    height: 35rem;
    width: 33.938rem;
    margin-left: -0.313rem;
    overflow-y: scroll;
  }

  &__description {
    margin-top: 1rem;
    label {
      span {
        color: $color-txt-black;
      }
    }
  }
}
