.section {
    background-color: $color-background;
    @include box-shaddow-thin;
    border-radius: 0.625rem;
    overflow: hidden;
    
    &--mt-1 {
        margin-top: 0.9375rem;
    }
    &--ml-1 {
        margin-left: 0.9375rem;
        &.ant-col-7 {
            display: block;
            -ms-flex: 0 0 calc(29.16666667% - 1rem);
            flex: 0 0 calc(29.16666667% - 1rem);
            max-width: calc(29.16666667% - 1rem);
        }
    }

    &--padding {
        &-all {
            padding: 0.2rem 5.25rem 0.2rem 3.4rem;
        }
    }
}