.leasing-form-selector {
  &__wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1.25rem 6rem;

    & div.ant-col {
      padding: 1.25rem 3rem;
    }
  }

  &__option {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-items: center;
    border: 0px;

    & span {
      font-size: 0.9375rem;
    }
  }

  &__submit {
    text-align: center;
  }
}
