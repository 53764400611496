.banners {
  margin-top: 1.175rem;
  > div:not(:last-child) {
    margin-bottom: 0.7rem;
  }
  .bannerImage {
    width: 100%;
    padding-left: 0.9375rem;
    img {
      border-radius: 0.5rem;
    }
  }
}
