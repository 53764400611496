.dokobit-container {
  @include screen("tablet") {
    top: 2rem;
  }

  @include screen("phone") {
    top: 0;
  }

  &__frame {
    border-width: 0;
    overflow: hidden;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
  }

  .ant-modal-content {
    max-width: 100%;
    .ant-modal-body {
      padding: 0;
    }
  }
}
