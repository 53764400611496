.uploadDocuments {
  .uploadManually {
    input {
      border: 0 none;
    }

    .ant-picker-suffix {
      color: $color-sme-input-icons;
    }

    .invoiceAttachment,
    .attachment,
    .ant-select-selector {
      cursor: pointer;
    }

    .ant-input-group-addon,
    .currency {
      .ant-select-arrow {
        display: none;
      }
    }

    .transferClaim {
      .ant-input-number {
        width: 100%;
      }
    }

    .currency {
      margin-right: unset;

      .ant-col.ant-form-item-label {
        max-width: none;

        label {
          width: 15rem;
        }
      }

      .ant-select-selector {
        margin-top: -0.0625rem;
      }
    }

    .ant-form,
    .ant-select-selector,
    .ant-select-selection-search-input,
    .ant-input,
    .ant-upload,
    label,
    .ant-input-number-input-wrap input,
    .ant-input-number-input {
      font-size: 0.9375rem;
    }

    .ant-form-item-label label {
      font-size: 0.8125rem;
    }
  }
}
